<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useRadar } from '~/store/radar'
import { useRoot } from '~/store'
import type { RouteComponent } from '~/types/custom'

const radar = useRadar()
const root = useRoot()
const { routeComponents } = storeToRefs(radar)

const { scrollEnabled } = storeToRefs(root)

const slots = computed(() => routeComponents.value.map((component: RouteComponent) => component?.letter?.word))
</script>

<template lang="pug">
.container
  .row(
    v-for="(slot, index) in slots"
    :id="`${slot}-section`"
    :key="`slot-${index}`"
    data-test="grid"
    :class="scrollEnabled ? 'h-[100dvh]' : 'py-32'"
  )
    .col-12
      slot(:name="slot")
</template>

<style lang="sass" scoped>
.container
  @apply px-0
  .row
    @apply border-b border-primary
  .scroller
    @apply fixed bottom-20 left-[50%] translate-x-[-50%]
</style>
